import NextImage from 'next/image'

// The idea with this component is to get the best of both worlds when loading images
// We don't want to eagerly load all images at initial page load
// but we do want to eager load our images before the user scrolls them into view

// Additionally we can use this as a place to set a default loading image placeholder.

// priority="false" adds loading="lazy" to the img tag.
// priority="true" simply removes loading="lazy" from the img tag.

// This loader will prevent the image request being proxied through our server
// and instead go directly to the contentful image cdn.
const loader = ({ src, width }) => {
  // Always return the Achieve logo without extra formatting options
  if (src === '/2022_Achieve_Logo_RGB.svg') return src

  // Allow all other svgs to specify a width
  if (src.endsWith('.svg')) return `${src}?w=${width}`

  // By default, return non-svgs formatted as webp, 50% quality
  return `${src}?fm=webp&q=50`
}

function Image(props) {
  const { src } = props

  // src need to be a string for NextImage to work
  if (!src || typeof src !== 'string') return null
  // There is this really awful reverse vignette effect that is baked into next/image placeholder.
  // So I matched the color and opacity of the placeholder 1x1 pixel image to match the vignette effect.
  // const blurDataURL =
  //   'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAA1JREFUGFdjYGBgMAIAADcAMwsWy8IAAAAASUVORK5CYII='

  const imgProps = {}

  // To prevent warnings about placeholder blur performance, do not set on small images.
  // I couldn't find a definition for small images, but 100 works for now.
  // const minimumSize = 100
  // if (
  //   !(
  //     typeof width === 'number' &&
  //     typeof height === 'number' &&
  //     width < minimumSize &&
  //     height < minimumSize
  //   ) &&
  //   !src.endsWith('.svg')
  // ) {
  //   imgProps.placeholder = 'blur'
  //   imgProps.blurDataURL = blurDataURL
  // }

  // Allow overriding any of the imgProps
  return <NextImage loader={loader} {...imgProps} {...props} />
}

export { Image }
