import useDTIDataForm from 'hooks/useDTIDataForm/useDTIDataForm'
import { Box } from '@achieve/ascend'
import { CalculatorSection } from '@achieve/cx-dti-visualizer'
import Section from 'components/Section/Section'
import styles from './DTICalculator.module.scss'
import { TrackingWrapper } from 'components/Tracking/TrackingWrapper'
import { useEffect, useRef, useState } from 'react'

function DTICalculator({ content }) {
  const [, setDtiDataForm] = useDTIDataForm()
  const configDti =
    (content?.fields?.additionalConfiguration?.jsonContent ??
      content?.fields?.additionalConfiguration?.fields?.jsonContent) ||
    {}
  const [formResults, setFormResults] = useState({})
  const [formParameters, setFormParameters] = useState({})
  const trackRefSubmit = useRef()
  const trackRefCta = useRef()
  const trackRefApiResponse = useRef()

  const widthConfig = configDti.fullWidth

  const args = {
    ...configDti,
    onCalculate: (response) => setResponse(response),
    onChangeParameters: (parameters) => {
      setFormParameters(parameters)
    },
    onCtaClick: () => trackRefCta?.current?.click(),
    onExploreOptions: () => trackRefCta?.current?.click(),
  }

  const setResponse = (response) => {
    setFormResults(response)
    setDtiDataForm({
      dataDti: response,
    })
    if (response && response !== args.defaultResponse) trackRefApiResponse?.current?.click()
  }

  useEffect(() => {
    trackRefSubmit?.current?.click()
  }, [formParameters])

  return (
    <Section
      className={styles[widthConfig ? 'wider-container' : 'container']}
      backgroundColor={configDti?.pageSectionBackgroundColor}
    >
      <Box className={styles['box-form']}>
        <TrackingWrapper
          data-testid="dti-submit"
          track={{
            list_name: 'DTI SUBMIT',
            event_action: args.manualSubmit ? 'button_click' : 'input_change',
            track_event: args.manualSubmit ? 'button_click' : 'input_change',
            form_ss_monthly_expenses: formParameters.expenses,
            form_ss_monthly_income: formParameters.income,
            form_ss_amount: formParameters.total,
          }}
        >
          <div ref={trackRefSubmit}></div>
        </TrackingWrapper>
        <TrackingWrapper
          data-testid="dti-response"
          track={{
            list_name: 'DTI API RESPONSE',
            tool_response: JSON.stringify(formResults),
            form_ss_monthly_expenses: formParameters.expenses,
            form_ss_monthly_income: formParameters.income,
            form_ss_amount: formParameters.total,
          }}
        >
          <div ref={trackRefApiResponse}></div>
        </TrackingWrapper>
        <TrackingWrapper
          data-testid="dti-click"
          track={{
            list_name: 'DTI CTA CLICK',
            tool_response: JSON.stringify(formResults),
            form_ss_monthly_expenses: formParameters.expenses,
            form_ss_monthly_income: formParameters.income,
            form_ss_amount: formParameters.total,
            href: 'get-started',
          }}
        >
          <div ref={trackRefCta}></div>
        </TrackingWrapper>
        <CalculatorSection {...args} />
      </Box>
    </Section>
  )
}
export { DTICalculator }
export default DTICalculator
