import styles from 'components/IconListItem/IconListItem.module.scss'
import { BLOCKS } from '@contentful/rich-text-types'
import { Typography } from 'components/Contentful'
import {
  Users,
  User,
  ThumbsUp,
  Watch,
  Calendar,
  Percent,
  DollarSign,
  ArrowDown,
  Smile,
  Key,
  Scissors,
  Zap,
  BarChart,
  PieChart,
} from 'react-feather'
import classNames from 'classnames'

const DEFAULT_ICON_SIZE = 15

const ICON_LIST = {
  Users,
  User,
  ThumbsUp,
  Watch,
  Calendar,
  Percent,
  DollarSign,
  ArrowDown,
  Smile,
  Key,
  Scissors,
  Zap,
  Rocket: BarChart,
  PieChart,
}

function IconListItem({
  className,
  classNameIcon,
  classNameText,
  iconColor,
  iconName,
  iconSize = DEFAULT_ICON_SIZE,
  itemText,
  textColor,
  testIdPrefix = 'default',
  variant,
  noMarginBottom = false,
}) {
  const IconComponent = ICON_LIST[iconName]
  let textProps = {
    content: itemText,
    variantOverride: { [BLOCKS.PARAGRAPH]: 'bodySm' },
    fontWeight: 'regular',
  }

  if (textColor) {
    textProps.color = textColor
  }

  return (
    <li
      className={classNames(
        styles['icon-list-item'],
        styles['icon-item'],
        { [styles['no-margin-bottom']]: noMarginBottom },
        {
          [className]: Boolean(className),
        }
      )}
    >
      {IconComponent && (
        <IconComponent
          className={classNames(styles['icon-item-svg'], {
            [classNameIcon]: Boolean(classNameIcon),
          })}
          color={iconColor}
          width={iconSize}
          height={iconSize}
        />
      )}
      <Typography
        {...textProps}
        data-testid={`${testIdPrefix}-icon-list-item`}
        variant={variant}
        className={classNameText}
      />
    </li>
  )
}

export { ICON_LIST, IconListItem }
