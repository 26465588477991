import { trackEvent } from 'utils/analytics'
import { AnalyticsContext } from 'providers/AnalyticsProvider'
import { useContext } from 'react'
import { useSessionId } from 'utils/analytics/session'

const useTriggerEvents = () => {
  const {
    state: {
      analyticsInstance,
      trackingData,
      isPageLoaded,
      auth0_email,
      auth0_user_id,
      profile_id,
      config,
    },
  } = useContext(AnalyticsContext)

  // Will reset session start time and create new session if last event was >30 min prior
  useSessionId()

  const fireAndForget = (event) => {
    if (!isPageLoaded) return
    trackEvent(analyticsInstance, config, {
      ...trackingData,
      auth0_email,
      auth0_user_id,
      profile_id,
      ...event,
    })
  }

  const waitForNavigation = async (event) => {
    if (!isPageLoaded) return
    await trackEvent(analyticsInstance, config, {
      ...trackingData,
      auth0_email,
      auth0_user_id,
      profile_id,
      ...event,
    })
  }

  return { fireAndForget, waitForNavigation }
}
export { useTriggerEvents }
